import $ from 'jquery'
$(document).ready( initPostsSlider )

function initPostsSlider(){
  if ($('.js-posts-slider').length) {
    $('.js-posts-slider').slick({
      arrows: true,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}
