import $ from 'jquery'

const $doc = $(document)

function initAccordion() {
  const firstElement = $('.js-accordion-item:first-child');
  firstElement.addClass('active');
  firstElement.find('.accordion__single-item-content').slideDown();
}

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('active');
}

function loadMembers(dept = '', color = '') {
  dept = $(this).attr('deptid');
  color = $(this).attr('deptcolor');
  var responseContainer = '#' + $(this).attr('accresponseid');

  const data = {
    dept: dept,
    color: color,
    action: 'ajax_team_loadmore'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $(responseContainer).find('.js-load-team').text('Loading...');
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null || !/\S/.test(htmlResponse)) {
        $(responseContainer).find('.js-load-team').text('No results found!');
      }
      else {
        if ($('body').find(responseContainer).find('.team-members').length === 0){
          $('body').find(responseContainer).append(htmlResponse);
          $(responseContainer).find('.js-load-team').remove();
        }
      }
    },
    error: function () {
      $(responseContainer).find('.js-load-team').text('Error.');
    },
  });
}


$doc.ready(initAccordion);
$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
$doc.on('click', '.js-load-members', loadMembers);