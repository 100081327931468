import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      arrows: false,
      // adaptiveHeight: true,
      fade: true,
      dots: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 52L26 27L2 2" stroke="#425050" stroke-width="4"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 2L3 27L27 52" stroke="#425050" stroke-width="4"/></svg></button>',
    });
  }
}
