import $ from 'jquery'

function initCtaHoverStates(e) {
    var currEl = e.target;
    $(".image-cta__link").addClass('fade-link');
    $(currEl).removeClass('fade-link').addClass('active');
}

function removeHoverStates() {
    $(".image-cta__link.fade-link").removeClass('fade-link');
}

$(".image-cta__link").on({
    mouseleave: function (e) {
        removeHoverStates(e)
    }, mouseenter: function (e) {
        initCtaHoverStates(e)
    }
})


$(".image-cta__accordion").on('click', function () {
    if(!$(this).hasClass('active-item')){
        $(".image-cta__accordion").find('h3').removeClass('active');
        $(".image-cta__accordion").find('.image-cta__accordion-text').hide('fast');
        $(".image-cta__accordion").find('.plusLine').show();
        $(".image-cta__accordion").removeClass('active-item');
    }
    $(this).find('h3').toggleClass('active');
    $(this).find('.image-cta__accordion-text').slideToggle();
    $(this).find('.plusLine').toggle();
    $(this).toggleClass('active-item');
})