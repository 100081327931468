import $ from 'jquery'
$(document).ready( init )

function init() {
    const videoWrapper = $('.video-file')
    const video = videoWrapper.find('video')
    const play = videoWrapper.find('.play-video')
    const pause = videoWrapper.find('.pause-video')

    $('.pause-video').click( function() {
        video.get(0).pause()
        pause.hide()
        play.show()
    })

    $('.play-video').click( function() {
        video.get(0).play()
        play.hide()
        pause.show()
        //unmute video
        video.get(0).muted = false
    })

}