import $ from 'jquery'

const $doc = $(document)
//const loader = '<svg style="margin: auto; background: rgb(255, 251, 245); display: block; shape-rendering: auto;" width="110px" height="110px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><rect x="17.5" y="30" width="15" height="40" fill="#fa7e65"><animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate><animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2s"></animate></rect><rect x="42.5" y="30" width="15" height="40" fill="#00ab69"><animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate><animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.1s"></animate></rect><rect x="67.5" y="30" width="15" height="40" fill="#6c99f4"><animate attributeName="y" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate><animate attributeName="height" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate></rect></svg>';
const loader = $('.js-loader');

function toggleTabItem() {
  const postType = $(this).data('type')
  const postCat = $(this).data('cat')
  const targetContent = $(this).data('target')
  const index = $(this).data('index')
  let el = $(this);

  el.parent().parent().parent().find('.js-tab-item').attr('aria-selected', 'false').attr('tabindex', '-1')
  el.parent().parent().parent().find('.js-tab-content').removeClass('active').attr('hidden', 'true')
  el.parent().parent().parent().find('.js-tab-item span').removeClass('active')

  el.attr('aria-selected', 'true').removeAttr('tabindex')
  el.find('span').addClass('active')
  $(`#${targetContent}`).addClass('active').removeAttr('hidden')

  if(postType) initTabs(postType, postCat, index);
}

function loadMore() {

  const data = {
    'action': 'loadmore',
    'query': query,
    'page' : current_page,
  };

  console.log(data)

  $.ajax({
      url : php_vars.ajax_url,
      data : data,
      type : 'POST',
      beforeSend : function ( xhr ) {
        $('.js-load-more').text('Loading...');
      },
      success : function( posts ){
        if( posts ) {

          $('.js-load-more').text( 'Load more' );
          $('.tabs__posts--list').append( posts );
          current_page++;

          if ( current_page == max_page ) 
            $('.js-load-more').hide();

        } else {
          $('.js-load-more').hide();
        }
      }
  });
  return false;
}

function initTabs(type, cat, index) {
  const data = {
    action : "get_tabs",
    type : type,
    cat : cat
  }
  console.log(data)
  toggleTabs(data, index);
}

function toggleTabs(data, index) {
  return $.ajax({
    url : php_vars.ajax_url,
    data : data,
    dataType : 'json',
    beforeSend : function(xhr){
      $('.tabs--posts__wrapper').empty();
      loader.addClass('loading')
      $('.js-load-more').hide();
    },
    success : function(response) {
         if( response ) {
          current_page = 1;
          query = response.posts;
          max_page = response.max_page;
          console.log(response);
          $('.tabs--posts__wrapper').removeClass($('.tabs--posts__wrapper').attr('class').split(' ').pop());
          $('.tabs--posts__wrapper').addClass('tabs--'+index);
          $('.tabs--posts__wrapper').html(response.content);
          loader.removeClass('loading');
          if ( response.max_page < 2 ) {
            $('.js-load-more').hide();
          } else {
            $('.js-load-more').show();
          }
        }
      }
  });
}

$( '.js-tabs' ).each(function() {
  var index = 0;
  var $tabs = $(this).find('.js-tab-item');

  $tabs.bind(
  {
    keydown: function(ev){
      var LEFT_ARROW = 37;
      var UP_ARROW = 38;
      var RIGHT_ARROW = 39;
      var DOWN_ARROW = 40;

      var k = ev.which || ev.keyCode;

      if (k >= LEFT_ARROW && k <= DOWN_ARROW){

        if (k == LEFT_ARROW || k == UP_ARROW){
          if (index > 0) {
            index--;
          }
          else {
            index = $tabs.length - 1;
          }
        }

        else if (k == RIGHT_ARROW || k == DOWN_ARROW){
          if (index < ($tabs.length - 1)){
            index++;
          }
          else {
            index = 0;
          }
        }

        $($tabs.get(index)).click();
        ev.preventDefault();
      }
    },

    click: function(ev){
      index = $.inArray(this, $tabs.get());
      setFocus();
      ev.preventDefault();
    }
  });

  var setFocus = function(){

    $tabs.attr(
    {
      tabindex: '-1',
      'aria-selected': 'false'
    }).removeClass('active');

    $(this).find('.js-tab-content').removeClass('active').attr('hidden', 'true');

    $($tabs.get(index)).attr(
    {
      tabindex: '0',
      'aria-selected': 'true'
    }).addClass('active').focus();

    $($($tabs.get(index)).data('target')).addClass('active').removeAttr('hidden');
  };
});


$doc.on('click', '.js-tab-item', toggleTabItem )
$doc.on('click', '.js-load-more', loadMore )
$doc.ready( () => {
  const hash = window.location.hash;
  if (hash && hash.includes('+')) { 
    const tab = hash.split('+')[1]
    const button = $(`[data-type="${tab}"]`);
    button.on('click', toggleTabItem)
    button.trigger('click');
  }
})
