import $ from 'jquery'

function playVideoItem(event) {
  // event.preventDefault();
  var videoItem = $(event.currentTarget);
  console.log(videoItem);
  var videoSource = videoItem.data('source');
  var videoUrl = videoItem.data('video');
  console.log(videoSource);
  console.log(videoUrl);

  if (videoSource == 'youtube') {
    $.magnificPopup.open({
      items: {
        src: videoUrl,
        type: 'iframe',
      },
      midClick: true,
      removalDelay: 100,
      mainClass: 'popup--animate',
      iframe: {
        markup: '<div class="mfp-video-header">' +
          '<div class="mfp-close"></div>' +
          '</div>' +
          '<div class="mfp-iframe-scaler">' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
          '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
        patterns: {
          youtube: {
            index: 'youtube.com',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
          }
        }
      }
    });
  } else if (videoSource == 'vimeo') {
    $.magnificPopup.open({
      items: {
        src: videoUrl,
        type: 'iframe',
      },
      midClick: true,
      removalDelay: 100,
      mainClass: 'popup--animate',
      iframe: {
        markup: '<div class="mfp-video-header">' +
          '<div class="mfp-close"></div>' +
          '</div>' +
          '<div class="mfp-iframe-scaler">' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen allow="autoplay"></iframe>' +
          '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
        patterns: {
          vimeo: {
            index: 'vimeo.com/',
            id: function(src) {
              if (src.indexOf('external') > -1) {
                return 'external/' + src.substr(src.lastIndexOf('/') + 1, src.length);
              } else {
                return 'video/' + src.substr(src.lastIndexOf('/') + 1, src.length);
              }
            },
            src: '//player.vimeo.com/%id%?autoplay=1&autopause=0&playsinline=1'
          }
        }
      }
    });
  }
}

$(document).on('click', '.js-video-poster-play', playVideoItem )



