import $ from 'jquery'

const $doc = $(document)

function initForm() {
    // Select the first row, second row, and third row of the form
    const firstName = $(".e2ma_signup_form_row:first-of-type");
    const lastName = $(".e2ma_signup_form_row:nth-of-type(2)");
    const email = $(".e2ma_signup_form_row:nth-of-type(3)");

    // Set placeholders for the input fields
    firstName.find("input").attr("placeholder", "First name");
    lastName.find("input").attr("placeholder", "Last name");
    email.find("input").attr("placeholder", "Email");
  
}

$doc.ready(initForm);