import $ from 'jquery'

const $doc = $(document)

function toggleItem() {
  let el = $(this);
  if ( el.parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.next().slideToggle('fast');
  el.parent().toggleClass('active');
}

$doc.on('click', '.js-learn-more', toggleItem )
